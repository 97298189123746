<template>
    <div>
        <div class="features js-features" id="servizi">
        <div class="features__center center">
            <div class="features__head" data-aos="animation-scale-y">
                <div class="features__stage stage">Servizi</div>
                <div class="features__box">
                    <h2 class="features__title title">La tua idea, il nostro design, il tuo successo.</h2>
                    <div class="features__info info">Dal concept alla realizzazione, i nostri servizi trasformano la tua visione in esperienze digitali uniche.</div>
                </div>
            </div>
            <div class="features__body">
                <div class="features__container">
                    <div class="features__slider js-features-slider">
                        <div class="features__slide" data-aos="animation-scale-y">
                            <div class="features__details">
                                <div class="features__preview"><img class="features__pic" src="img/progettazione.png" alt="Progettazione "></div>
                                <div class="features__category">Progettazione</div>
                                <div class="features__text">Inizia con una fase di analisi approfondita, seguita da una progettazione concettuale dove sviluppiamo le prime idee e definiamo l'architettura del progetto.</div><a class="features__link" href="#comelavoriamo">Scopri di più</a>
                            </div>
                        </div>
                        <div class="features__slide" data-aos="animation-scale-y" data-aos-delay="200">
                            <div class="features__details">
                                <div class="features__preview"><img class="features__pic" src="img/design.png" alt="Design "></div>
                                <div class="features__category">Design</div>
                                <div class="features__text">Diamo vita a un'interfaccia visiva accattivante e all'esperienza utente perfetta. Questo processo coinvolge iterazioni continue e test per garantire la massima usabilità.</div><a class="features__link" href="#design">Scopri di più</a>
                            </div>
                        </div>
                        <div class="features__slide">
                            <div class="features__details">
                                <div class="features__preview"><img class="features__pic" src="img/development.png" alt="Development "></div>
                                <div class="features__category">Sviluppo</div>
                                <div class="features__text">Dopo l'approvazione del design, passiamo alla fase di implementazione, dove i nostri esperti trasformano il concept in una realtà digitale impeccabile, con l'ausilio delle più moderne e affidabili tecnologie.</div><a class="features__link" @click="slideToDev" href="#sviluppo">Scopri di più</a>
                            </div>
                        </div>
                        <div class="features__slide">
                            <div class="features__details">
                                <div class="features__preview"><img class="features__pic" src="img/infrastructure.png" alt="Infrastructure "></div>
                                <div class="features__category">Infrastruttura</div>
                                <div class="features__text">Gestiamo l'infrastruttura con una combinazione di affidabilità consolidata e soluzioni all'avanguardia, garantendo un ambiente tecnologicamente robusto e contemporaneo.</div>
                                <a class="features__link" href="#tecnologie">Scopri di più</a>
                                <!--<a class="features__link" href="#">Scopri di più</a>-->
                            </div>
                        </div>
                        <div class="features__slide">
                            <div class="features__details">
                                <div class="features__preview"><img class="features__pic" src="img/support.png" alt="Support "></div>
                                <div class="features__category">Supporto</div>
                                <div class="features__text">Ci occupiamo di mantenere e migliorare costantemente il prodotto, fornendo assistenza tecnica, risolvendo problemi, applicando aggiornamenti e garantendo la sua perfetta funzionalità nel tempo.</div>
                                <!--<a class="features__link" href="#">Scopri di più</a>-->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="features__status js-features-status"></div>
            </div>
        </div>
        <div class="features__circle js-rellax" data-rellax-speed="-.7"></div>
        <div class="features__bg" data-aos data-aos-duration="1000"><img class="features__pic" src="img/features-pic-1.svg" alt="Feature Overlay "></div>
    </div>
    <div class="quality">
        <div class="quality__head">
            <div class="quality__center center">
                <div class="quality__top" data-aos="animation-scale-y">
                    <div class="quality__stage stage">Idee</div>
                    <h2 class="quality__title title">Lavoriamo insieme <br>per un progetto brillante<span class="title__color">.</span></h2>
                </div>
                <div class="quality__info info" data-aos="animation-scale-y" data-aos-delay="200">Concedici l'opportunità di dare vita alle tue idee, trasformando la tua visione in realtà.</div>
            </div>
            <div class="quality__line"><img class="quality__pic" src="img/line-3.svg" alt="Line Overlay "></div>
            <div class="quality__preview"><img class="quality__pic js-parallax" data-scale="1.2" data-orientation="left" src="img/lamp.png" alt="Lamp "></div>
        </div>
        <!--<div class="quality__body">
            <div class="quality__col">
                <div class="quality__item" data-aos="animation-scale-x-left">
                    <div class="quality__icon"><img class="quality__pic" src="img/camera-big.png" alt=""></div>
                    <div class="quality__details">
                        <div class="quality__category">Assets Library</div>
                        <div class="quality__text">Get access to huge library of ready-made components and illustrations, free to use on all your projects.</div><a class="quality__link" href="#">Learn more<svg class="icon icon-arrow-right">
                                <use xlink:href="img/sprite.svg#icon-arrow-right"></use>
                            </svg></a>
                    </div>
                </div>
            </div>
            <div class="quality__col">
                <div class="quality__item" data-aos="animation-scale-x-right" data-aos-delay="200">
                    <div class="quality__icon"><img class="quality__pic" src="img/clock-big.png" alt=""></div>
                    <div class="quality__details">
                        <div class="quality__category">Save Time</div>
                        <div class="quality__text">Save tons of time by eliminating repetitive day-to-day tasks and slow load times. </div><a class="quality__link" href="#">Learn more<svg class="icon icon-arrow-right">
                                <use xlink:href="img/sprite.svg#icon-arrow-right"></use>
                            </svg></a>
                    </div>
                </div>
            </div>
            <div class="quality__col">
                <div class="quality__item" data-aos="animation-scale-x-left">
                    <div class="quality__icon"><img class="quality__pic" src="img/cup-big.png" alt=""></div>
                    <div class="quality__details">
                        <div class="quality__category">Relax</div>
                        <div class="quality__text">Let your personalized Collab assistant do the heavy lifting while you focus on creating.Say good bye to code 👋</div><a class="quality__link" href="#">Learn more<svg class="icon icon-arrow-right">
                                <use xlink:href="img/sprite.svg#icon-arrow-right"></use>
                            </svg></a>
                    </div>
                </div>
            </div>
            <div class="quality__col">
                <div class="quality__item" data-aos="animation-scale-x-right" data-aos-delay="200">
                    <div class="quality__icon"><img class="quality__pic" src="img/chatbox-big.png" alt=""></div>
                    <div class="quality__details">
                        <div class="quality__category">Support</div>
                        <div class="quality__text">Got stuck or need any help? Our support team is online 24/7 to help assist you with any issues.</div><a class="quality__link" href="#">Learn more<svg class="icon icon-arrow-right">
                                <use xlink:href="img/sprite.svg#icon-arrow-right"></use>
                            </svg></a>
                    </div>
                </div>
            </div>
        </div>-->
        <div class="quality__circles">
            <div class="quality__circle"> </div>
            <div class="quality__circle"> </div>
        </div>
    </div>

    <div class="showcase" id="design">
        <div class="showcase__center center">
            <div class="showcase__wrap">
                <div class="showcase__top" data-aos="animation-scale-y">
                    <div class="showcase__stage stage">In primo piano</div>
                    <h2 class="showcase__title title">Design nell'aria<span class="title__color">.</span></h2>
                </div>
                <div class="showcase__info info" data-aos="animation-scale-y" data-aos-delay="150">Lascia che i vostri progetti raccontino la nostra storia.</div>
                <div class="showcase__btn" data-aos="animation-scale-y" data-aos-delay="300"><button class="btn btn_purple">Scopri i nostri progetti</button></div>
            </div>
        </div>
        <div class="showcase__bg"><img class="showcase__pic" src="img/showcase-circle.svg" alt="Showcase Circle "></div>
        <div class="showcase__circles">
            <div class="showcase__circle"></div>
            <div class="showcase__circle"></div>
        </div>
        <div class="showcase__list">
            <div class="showcase__preview"><img class="showcase__pic js-parallax" data-scale="1.3" data-orientation="right" src="img/group-1.png" alt="App Mockups "></div>
            <div class="showcase__preview"><img class="showcase__pic js-parallax" data-scale="1.3" data-orientation="left" src="img/group-2.png" alt="App Mockups "></div>
        </div>
    </div>

    <div class="work" id="comelavoriamo">
        <div class="work__center center">
            <div class="work__head">
                <div class="work__stage stage">Come lavoriamo</div>
                <h2 class="work__title title"><span class="title__color">Il tuo </span>progetto<br> <span class="title__color">Il nostro team</span></h2>
            </div>
            <div class="work__details" data-aos="animation-translate-y" data-aos-duration="800">
                <div class="work__icon"><img class="work__pic" src="img/balls.svg" alt="Balls "></div>
                <div class="work__text">Le tue idee sono il cuore del nostro lavoro e il nostro team si impegna per trasformarle in progetti di successo.</div><a class="work__link" href="#recensioni">Scopri di più</a>
            </div>
        </div>
        <div class="work__hands">
            <div class="work__hand" data-aos data-aos-duration="800" data-aos-offset="0"><img class="work__pic js-parallax" data-scale="1.2" data-orientation="down" src="img/hand-2.png" alt="Hand "></div>
            <div class="work__hand" data-aos data-aos-duration="800" data-aos-offset="0"><img class="work__pic js-parallax" data-scale="1.3" data-orientation="up" src="img/hand-3.png" alt="Hand "></div>
        </div>
        <div class="work__wave"><img class="work__pic" src="img/wave.svg" alt="Wave "></div>
        <div class="work__preview" data-aos data-aos-duration="600"><img class="work__pic" src="img/work-pic.png" alt="Work "></div>
        <div class="work__circles">
            <div class="work__circle js-rellax" data-rellax-speed="1"></div>
            <div class="work__circle"></div>
            <div class="work__circle js-rellax" data-rellax-speed="-1"></div>
            <div class="work__circle"></div>
            <div class="work__circle"></div>
        </div>
        <div class="work__cursor" data-aos><img class="work__pic" src="img/cursor.png" alt="Cursor "></div>
    </div>
    <!--<div class="teams" id="recensioni">
        <div class="teams__center center">
            <div class="teams__head" data-aos="animation-scale-y">
                <div class="teams__stage stage" style="color: rgb(64, 64, 64);">Soddisfazione dei clienti <span class="title__color">a parole loro</span>.</div>
                <h2 class="teams__title title">Il nostro lavoro raccontato dai nostri clienti<span class="title__color">.</span></h2>
            </div>
            <div class="teams__body">
                <div class="teams__col" data-aos="animation-scale-y">
                    <h3 class="teams__title title title_sm">Cosa&nbsp;dicono di noi</h3>
                </div>
                <div class="teams__col">
                    <div class="teams__container">
                        <div class="teams__slider js-teams-slider">
                            <div class="teams__slide" data-aos="animation-scale-y" data-aos-delay="200">
                                <div class="teams__line" style="background-color: #5956E9;"></div>
                                <div class="teams__name" style="color: #C75C6F;">GlobalPesca</div>
                                <div class="teams__text">“Recensione di Francescone qui. <br>Racconta qualcosa e qualcosa e qualcosa ancora.”</div>
                                <div class="teams__user">
                                    <div class="teams__ava"><img class="teams__pic" src="img/ui8.png" alt=""></div>
                                    <div class="teams__box">
                                        <div class="teams__man">Francesco P.</div>
                                        <div class="teams__login">GlobalPesca SpA</div>
                                    </div>
                                </div>
                            </div>
                            <div class="teams__slide" data-aos="animation-scale-y" data-aos-delay="400">
                                <div class="teams__line" style="background-color: #FAB8C4;"></div>
                                <div class="teams__name" style="color: #5956E9;">Tourial</div>
                                <div class="teams__text">“Ciao Alessio, scusa, solo un'ultima cosa: <br>Non è che, per caso, potresti, quando hai tempo.. ”</div>
                                <div class="teams__user">
                                    <div class="teams__ava"><img class="teams__pic" src="img/ava-1.png" alt=""></div>
                                    <div class="teams__box">
                                        <div class="teams__man">Giorgio V.</div>
                                        <div class="teams__login">Tourial</div>
                                    </div>
                                </div>
                            </div>
                            <div class="teams__slide" data-aos="animation-scale-y" data-aos-delay="200">
                                <div class="teams__line" style="background-color: #5956E9;"></div>
                                <div class="teams__name" style="color: #C75C6F;">GlobalPesca</div>
                                <div class="teams__text">“Recensione di Francescone qui. <br>Racconta qualcosa e qualcosa e qualcosa ancora.”</div>
                                <div class="teams__user">
                                    <div class="teams__ava"><img class="teams__pic" src="img/ui8.png" alt=""></div>
                                    <div class="teams__box">
                                        <div class="teams__man">Francesco P.</div>
                                        <div class="teams__login">GlobalPesca SpA</div>
                                    </div>
                                </div>
                            </div>
                            <div class="teams__slide" data-aos="animation-scale-y" data-aos-delay="400">
                                <div class="teams__line" style="background-color: #FAB8C4;"></div>
                                <div class="teams__name" style="color: #5956E9;">Tourial</div>
                                <div class="teams__text">“Ciao Alessio, scusa, solo un'ultima cosa: <br>Non è che, per caso, potresti, quando hai tempo.. ”</div>
                                <div class="teams__user">
                                    <div class="teams__ava"><img class="teams__pic" src="img/ava-1.png" alt=""></div>
                                    <div class="teams__box">
                                        <div class="teams__man">Giorgio V.</div>
                                        <div class="teams__login">Tourial</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="teams__circle js-rellax" data-rellax-speed="1"></div>
    </div>-->

   

    <div class="package js-package" id="sviluppo" style="scroll-margin-top: 80px;">
        <div class="package__center center">
            <div class="package__container">
                <div class="package__wrap">
                    <div class="package__slider js-package-slider">
                        <div class="package__slide" id="design-slide">
                            <div class="package__row">
                                <div class="package__details" data-aos data-aos-duration="600" data-aos-delay="400">
                                    <div class="package__category">
                                        <div class="package__icon"><img class="package__pic" src="img/smile-2.svg" alt="Smile "></div>
                                        <div class="package__text">Tecnologie</div>
                                    </div>
                                    <h3 class="package__title title title_sm">Design</h3>
                                    <div class="package__info">Adottiamo le più recenti tecnologie di progettazione grafica per offrire servizi di design di alta qualità e per tradurre la tua visione in realtà.</div>
                                    <div class="package__list"><a class="package__link" href="https://figma.com" target="_blank">
                                            <div class="package__icon"><img class="package__pic" src="img/figma.png" alt="Figma Logo"></div>
                                            <div class="package__text">Figma</div>
                                        </a><a class="package__link" href="https://adobe.com/" target="_blank">
                                            <div class="package__icon"><img class="package__pic" src="img/adobe.png" alt="Adobe Logo"></div>
                                            <div class="package__text">Adobe</div>
                                        </a></div>
                                    <div class="package__btns">
                                        <div class="package__arrows"><button class="package__arrow js-package-prev" aria-label="arrow prev"><svg class="icon icon-arrow-prev">
                                                    <use xlink:href="img/sprite.svg#icon-arrow-prev"></use>
                                                </svg></button><button class="package__arrow js-package-next" aria-label="arrow next"><svg class="icon icon-arrow-next">
                                                    <use xlink:href="img/sprite.svg#icon-arrow-next"></use>
                                                </svg></button></div>
                                        <!--<a class="package__btn btn btn_purple" href="login.html">Get Started</a>-->
                                    </div>
                                </div>
                                <div class="package__preview"><img class="package__pic" src="img/laptop.png" alt="Laptop "></div>
                            </div>
                        </div>
                        <div class="package__slide">
                            <div class="package__row">
                                <div class="package__details" data-aos data-aos-duration="600" data-aos-delay="400">
                                    <div class="package__category">
                                        <div class="package__icon"><img class="package__pic" src="img/smile-2.svg" alt="Smile "></div>
                                        <div class="package__text">Tecnologie</div>
                                    </div>
                                    <h3 class="package__title title title_sm">Sviluppo</h3>
                                    <div class="package__info">Con l'adozione di linguaggi di programmazione moderni, l'impiego di framework e strumenti di sviluppo avanzati, realizziamo soluzioni digitali affidabili e scalabili.</div>
                                    <div class="package__list"><a class="package__link" href="https://vuejs.org" target="_blank">
                                            <div class="package__icon"><img class="package__pic" src="img/vuejs.png" alt="VueJS "></div>
                                            <div class="package__text">VueJS</div>
                                        </a><a class="package__link" href="https://go.dev/" target="_blank">
                                            <div class="package__icon"><img class="package__pic" src="img/golang.png" alt="GOLang "></div>
                                            <div class="package__text">GoLang</div>
                                        </a></div>
                                    <div class="package__btns">
                                        <div class="package__arrows"><button class="package__arrow js-package-prev" aria-label="arrow prev"><svg class="icon icon-arrow-prev">
                                                    <use xlink:href="img/sprite.svg#icon-arrow-prev"></use>
                                                </svg></button><button class="package__arrow js-package-next" aria-label="arrow next"><svg class="icon icon-arrow-next">
                                                    <use xlink:href="img/sprite.svg#icon-arrow-next"></use>
                                                </svg></button></div>
                                        <!--<a class="package__btn btn btn_purple" href="login.html">Get Started</a>-->
                                    </div>
                                </div>
                                <div class="package__preview"><img class="package__pic" src="img/laptop.png" alt="Laptop "></div>
                            </div>
                        </div>
                        <div class="package__slide">
                            <div class="package__row">
                                <div class="package__details" data-aos data-aos-duration="600" data-aos-delay="400">
                                    <div class="package__category">
                                        <div class="package__icon"><img class="package__pic" src="img/smile-2.svg" alt="Smile "></div>
                                        <div class="package__text">Tecnologie</div>
                                    </div>
                                    <h3 class="package__title title title_sm">Infrastruttura</h3>
                                    <div class="package__info">Dalla virtualizzazione e l'automazione all'utilizzo di soluzioni cloud computing, ci affidiamo a strumenti all'avanguardia per garantire che la tua infrastruttura abbia una gestione efficiente e ottimale delle risorse digitali.</div>
                                    <div class="package__list"><a class="package__link" href="https://kubernetes.io/it/" target="_blank">
                                            <div class="package__icon"><img class="package__pic" src="img/kubernetes.png" alt="Kubernetes "></div>
                                            <div class="package__text">Kubernetes</div>
                                        </a><a class="package__link" href="https://www.openstack.org/" target="_blank">
                                            <div class="package__icon"><img class="package__pic" src="img/openstack.png" alt="OpenStack "></div>
                                            <div class="package__text">OpenStack</div>
                                        </a></div>
                                    <div class="package__btns">
                                        <div class="package__arrows"><button class="package__arrow js-package-prev"><svg class="icon icon-arrow-prev">
                                                    <use xlink:href="img/sprite.svg#icon-arrow-prev"></use>
                                                </svg></button><button class="package__arrow js-package-next"><svg class="icon icon-arrow-next">
                                                    <use xlink:href="img/sprite.svg#icon-arrow-next"></use>
                                                </svg></button></div>
                                        <!--<a class="package__btn btn btn_purple" href="login.html">Get Started</a>-->
                                    </div>
                                </div>
                                <div class="package__preview"><img class="package__pic" src="img/laptop.png" alt="Laptop "></div>
                            </div>
                        </div>
                        <div class="package__slide">
                            <div class="package__row">
                                <div class="package__details" data-aos data-aos-duration="600" data-aos-delay="400">
                                    <div class="package__category">
                                        <div class="package__icon"><img class="package__pic" src="img/smile-2.svg" alt="Smile "></div>
                                        <div class="package__text">Tecnologie</div>
                                    </div>
                                    <h3 class="package__title title title_sm">Supporto</h3>
                                    <div class="package__info">Utilizziamo strumenti di monitoraggio e gestione avanzati, sistemi di ticketing efficienti e soluzioni di assistenza remota per garantire una risposta rapida ed efficace alle tue esigenze.</div>
                                    <div class="package__list"><a class="package__link" href="https://prometheus.io/" target="_blank">
                                            <div class="package__icon"><img class="package__pic" src="img/prometheus.png" alt="Prometheus "></div>
                                            <div class="package__text">Prometheus</div>
                                        </a><a class="package__link" href="https://grafana.com/" target="_blank">
                                            <div class="package__icon"><img class="package__pic" src="img/grafana.png" alt="Grafana "></div>
                                            <div class="package__text">Grafana</div>
                                        </a></div>
                                    <div class="package__btns">
                                        <div class="package__arrows"><button class="package__arrow js-package-prev"><svg class="icon icon-arrow-prev">
                                                    <use xlink:href="img/sprite.svg#icon-arrow-prev"></use>
                                                </svg></button><button class="package__arrow js-package-next"><svg class="icon icon-arrow-next">
                                                    <use xlink:href="img/sprite.svg#icon-arrow-next"></use>
                                                </svg></button></div>
                                        <!--<a class="package__btn btn btn_purple" href="login.html">Get Started</a>-->
                                    </div>
                                </div>
                                <div class="package__preview"><img class="package__pic" src="img/laptop.png" alt="Laptop "></div>
                            </div>
                        </div>
                    </div>
                    <div class="package__line"><img class="package__pic" src="img/line-1.svg" alt="Line "></div>
                    <div class="package__circle" data-aos data-aos-duration="600"></div>
                </div>
                <div class="package__circles">
                    <div class="package__circle"></div>
                    <div class="package__circle"></div>
                </div>
            </div>
        </div>
    </div>

    <div class="partners" id="tecnologie">
        <div class="partners__center center">
            <div class="partners__head" data-aos="animation-scale-y">
                <div class="partners__stage stage">Tecnologie</div>
                <div class="partners__details">
                    <h2 class="partners__title title" style="font-size: 4rem;">Guidati dalla <span class="title__color">Tecnologia</span>,<br />ispirati dall'<span style="color: #15eeb1;">Innovazione</span>.</h2>
                    <div class="partners__info info">Naviga il futuro con le tecnologie all'avanguardia che guidano la nostra azienda.</div>
                </div>
            </div>
            <div class="partners__list">
                <div class="partners__item" data-aos><img class="partners__pic" src="img/w_kubernetes.png" alt="Kubernetes "></div>
                <div class="partners__item" data-aos data-aos-delay="150"><img class="partners__pic" src="img/w_grafana.png" alt="Grafana "></div>
                <div class="partners__item" data-aos data-aos-delay="300"><img class="partners__pic" src="img/w_prometheus.png" alt="Prometheus "></div>
                <div class="partners__item" data-aos data-aos-delay="450"><img class="partners__pic" src="img/w_gitlab.png" alt="GitLab "></div>
                <div class="partners__item" data-aos><img class="partners__pic" src="img/w_nginx.png" alt="Nginx "></div>
                <div class="partners__item" data-aos data-aos-delay="150"><img class="partners__pic" src="img/w_docker.png" alt="Docker "></div>
                <div class="partners__item" data-aos data-aos-delay="300"><img class="partners__pic" src="img/w_mariadb.png" alt="MariaDB "></div>
                <div class="partners__item" data-aos data-aos-delay="450"><img class="partners__pic" src="img/w_openstack.png" alt="OpenStack "></div>
            </div>
        </div>
        <div class="partners__preview" data-aos data-aos-duration="1000"><img class="partners__pic" src="img/partners-pic.svg" alt="Partners "></div>
        <div class="partners__circle js-rellax" data-rellax-speed=".6"></div>
    </div>

    <div class="layouts">
        <div class="layouts__center center">
            <div class="layouts__wrap">
                <div class="layouts__head" data-aos="animation-scale-y">
                    <div class="layouts__stage stage">UI/UX</div>
                    <h2 class="layouts__title title">Design innovativi<span class="title__color">.</span></h2>
                </div>
                <div class="layouts__info info" data-aos="animation-scale-y">Dall'Idea all'Innovazione: design che ridefiniscono gli standard.</div>
                <div class="layouts__list">
                    <div class="layouts__item" data-aos="animation-scale-y">
                        <div class="layouts__icon"><img class="layouts__pic" src="img/layout-1.svg" alt="Modern "></div>
                        <div class="layouts__text">Moderno</div>
                    </div>
                    <div class="layouts__item" data-aos="animation-scale-y">
                        <div class="layouts__icon"><img class="layouts__pic" src="img/layout-2.svg" alt="User Friendly "></div>
                        <div class="layouts__text">Intuitivo</div>
                    </div>
                    <div class="layouts__item" data-aos="animation-scale-y">
                        <div class="layouts__icon"><img class="layouts__pic" src="img/layout-3.svg" alt="Responsive "></div>
                        <div class="layouts__text">Responsive</div>
                    </div>
                </div>
            </div>
            <div class="layouts__view" data-aos>
                <div class="layouts__preview"><img class="layouts__pic" src="img/layouts-pic-1.png" alt="Layout "></div>
                <div class="layouts__preview"><img class="layouts__pic" src="img/layouts-pic-2.png" alt="Layout "></div>
                <div class="layouts__preview"><img class="layouts__pic" src="img/layouts-pic-3.png" alt="Layout "></div>
                <div class="layouts__preview"><img class="layouts__pic" src="img/layouts-pic-4.png" alt="Layout "></div>
                <div class="layouts__preview"><img class="layouts__pic" src="img/layouts-pic-5.png" alt="Layout "></div>
            </div>
        </div>
    </div>

    <div class="offer">
        <div class="offer__center center">
            <div class="offer__container">
                <div class="offer__head">
                    <div class="offer__info info" data-aos="animation-scale-y" data-aos-delay="150">Sviluppiamo la tua idea insieme.</div>

                    <h2 class="offer__title title" data-aos="animation-scale-y">Noi siamo pronti,<br />tu lo sei?</h2>
                    <div class="offer__btn" data-aos="animation-scale-y" data-aos-delay="300"><a class="btn btn_white contact-us-btn" href="#popup-contact" data-effect="mfp-zoom-in">Raccontaci la tua idea</a></div>
                </div>
                <div class="offer__circles">
                    <div class="offer__circle"></div>
                    <div class="offer__circle"></div>
                </div>
            </div>
            <div class="offer__circle"></div>
        </div>
        <div class="offer__view" data-aos data-aos-delay="400" data-aos-offset="0">
            <div class="offer__preview"><img class="offer__pic" src="img/offer-pic-1.png" alt="User Card "></div>
            <div class="offer__preview"><img class="offer__pic" src="img/offer-pic-2.png" alt="Bell "></div>
            <div class="offer__preview"><img class="offer__pic" src="img/offer-pic-3.png" alt="Message "></div>
            <div class="offer__preview"><img class="offer__pic" src="img/offer-pic-4.png" alt="Rubber "></div>
            <div class="offer__preview"><img class="offer__pic" src="img/offer-pic-5.png" alt="Customization "></div>
        </div>
    </div>

    <!--<div class="like">
        <div class="like__center center">
            <div class="like__container">
                <div class="like__wrap">
                    <h2 class="like__title title" data-aos="animation-scale-x">Non so cosa mettere<span class="title__color">.</span></h2>
                    <div class="like__text" data-aos="animation-scale-x" data-aos-delay="150">
                        <p>Lorem ipsum dipsum, lorem ipsum dipsum, lorem ipsum dipsum.</p>
                        <p>Lorem ipsum dipsum, lorem ipsum dipsum, lorem ipsum dipsum.</p>
                    </div>
                    <div class="like__btn" data-aos="animation-scale-x" data-aos-delay="300"><button class="btn btn_purple">Tasto qualcosa</button></div>
                </div>
                <div class="like__preview" data-aos><img class="like__pic" src="img/hand-1.png" alt=""></div>
                <div class="like__circle"></div>
                <div class="like__circles">
                    <div class="like__circle"></div>
                    <div class="like__circle js-rellax" data-rellax-speed="-1"></div>
                    <div class="like__circle js-rellax" data-rellax-speed=".8"></div>
                    <div class="like__circle"></div>
                    <div class="like__circle"></div>
                </div>
            </div>
        </div>
    </div> -->

    <!--<div class="plan js-plan">
        <div class="plan__center center">
            <div class="plan__head" data-aos="animation-scale-y">
                <div class="plan__stage stage">Join The Revolution</div>
                <h2 class="plan__title title">Find the <span class="title__color">Right Plan.</span></h2>
                <div class="plan__info info">Flexible pricing options for freelancers and design teams.</div><label class="switch"><input class="switch__input" type="checkbox" checked><span class="switch__flex"><span class="switch__yearly">Billed Yearly</span><span class="switch__box"></span><span class="switch__monthly">Billed Monthly</span></span></label>
                <div class="plan__variants"><select class="plan__select js-plan-select">
                        <option>Professional</option>
                        <option>Standard</option>
                        <option>Ultimate</option>
                    </select></div>
            </div>
            <div class="plan__body">
                <div class="plan__item js-plan-item" data-aos="animation-translate-y">
                    <div class="plan__star"><svg class="icon icon-star">
                            <use xlink:href="img/sprite.svg#icon-star"></use>
                        </svg></div>
                    <div class="plan__category">
                        <div class="plan__icon"><img class="plan__pic" src="img/smile-1.svg" alt=""></div>
                        <div class="plan__text">Individual</div>
                    </div>
                    <h3 class="plan__title title title_sm">Professional</h3>
                    <div class="plan__price"><span class="plan__money">$19.99</span> /month</div>
                    <ul class="plan__list">
                        <li>1 user</li>
                        <li>2 TB of secure storage</li>
                        <li>Premium productivity features and simple, secure file sharing</li>
                    </ul><a class="plan__btn btn btn_white js-popup-open" href="#popup-contact" data-effect="mfp-zoom-in">Try free for 30 days</a>
                </div>
                <div class="plan__item js-plan-item" data-aos="animation-translate-y" data-aos-delay="200">
                    <div class="plan__category">
                        <div class="plan__icon"><img class="plan__pic" src="img/smile-2.svg" alt="Smile "></div>
                        <div class="plan__text">Small Team</div>
                    </div>
                    <h3 class="plan__title title title_sm">Standard</h3>
                    <div class="plan__price"><span class="plan__money">$29.99</span> /month</div>
                    <ul class="plan__list">
                        <li>3+ user</li>
                        <li>5 TB of secure storage</li>
                        <li>Premium productivity features and simple, secure file sharing</li>
                    </ul><a class="plan__btn btn btn_pink js-popup-open" href="#popup-contact" data-effect="mfp-zoom-in">Try free for 30 days</a>
                </div>
                <div class="plan__item js-plan-item" data-aos="animation-translate-y" data-aos-delay="400">
                    <div class="plan__category">
                        <div class="plan__icon"><img class="plan__pic" src="img/smile-2.svg" alt="Smile "></div>
                        <div class="plan__text">Large Team</div>
                    </div>
                    <h3 class="plan__title title title_sm">Ultimate</h3>
                    <div class="plan__price"><span class="plan__money">$99.99</span> /month</div>
                    <ul class="plan__list">
                        <li>10+ user</li>
                        <li>10 TB of secure storage</li>
                        <li>Premium productivity features and simple, secure file sharing</li>
                    </ul><a class="plan__btn btn btn_purple js-popup-open" href="#popup-contact" data-effect="mfp-zoom-in">Try free for 30 days</a>
                </div>
            </div>
        </div>
        <div class="plan__circle js-rellax" data-rellax-speed="-1"></div>
    </div>-->

    <div class="speed">
        <div class="speed__center center">
            <div class="speed__wrap">
                <div class="speed__head" data-aos="animation-scale-y">
                    <div class="speed__stage stage">Sicurezza</div>
                    <h2 class="speed__title title">Sicurezza <br>Prestazioni <br>Affidabilità<span class="title__color">.</span></h2>
                    <div class="speed__text">La nostra soluzione per rendere il tuo successo online una realtà.</div>
                </div>
                <div class="speed__number" data-aos="animation-scale-y"><span>2.5</span>x</div>
                <div class="speed__text">Aumento di velocità atteso del proprio sito web</div>
            </div>
        </div>
        <div class="speed__line"><img class="speed__pic" src="img/line.svg" alt="Line "></div>
        <div class="speed__preview" data-aos>
            <div class="speed__graphic"><img class="speed__pic" src="img/graphic.png" alt="Speed Optimization "></div>
            <div class="speed__plant"><img class="speed__pic js-parallax" data-scale="1.3" data-orientation="left" src="img/plant.png" alt="Plant "></div>
        </div>
        <div class="speed__circles">
            <div class="speed__circle js-rellax" data-rellax-speed="-1"></div>
            <div class="speed__circle js-rellax" data-rellax-speed="1"></div>
            <div class="speed__circle"></div>
        </div>
    </div>

    <!--<div class="demo">
        <div class="demo__center center">
            <div class="demo__head" data-aos="animation-scale-y">
                <div class="demo__icon">🔥</div>
                <div class="demo__info">We offer flexible pricing options for freelancers and design teams.</div>
            </div>
            <div class="demo__list">
                <div class="demo__item" data-aos="animation-translate-y">
                    <h3 class="demo__title title title_sm">Schedule <br>Product <br>Demo</h3>
                    <div class="demo__text">Still not sure? Schedule a demo to see how Collab can help you get more done.</div><a class="demo__btn btn btn_purple js-popup-open" href="#popup-contact" data-effect="mfp-zoom-in">Schedule a Demo</a>
                </div>
                <div class="demo__item" data-aos="animation-translate-y" data-aos-delay="200">
                    <h3 class="demo__title title title_sm">Get <br>Started <br>Now</h3>
                    <div class="demo__text">Ready to revolutionize your design workflow? Try Collab for free for 30 days.</div><a class="demo__btn btn btn_white js-popup-open" href="#popup-contact" data-effect="mfp-zoom-in">Try free for 30 days</a>
                </div>
            </div>
        </div>
        <div class="demo__circle js-rellax" data-rellax-speed="1"></div>
    </div>-->
    <!--<div class="design">
        <div class="design__center center">
            <div class="design__wrap" data-aos="animation-translate-y">
                <div class="design__stage stage">Design accordlogy</div>
                <h2 class="design__title title">Enrich your design workflow<span class="title__color">.</span></h2>
                <div class="design__info info">Design anything from simple icons to fully featured websites and applications.</div>
            </div>
            <div class="design__hand" data-aos data-aos-duration="600"><img class="design__pic" src="img/hand-4.png" alt=""></div>
            <div class="design__bg" data-aos data-aos-delay="400"><img class="design__pic" src="img/design-pic.png" alt=""></div>
            <div class="design__circles">
                <div class="design__circle js-rellax" data-rellax-speed="1"></div>
                <div class="design__circle"></div>
                <div class="design__circle js-rellax" data-rellax-speed=".5"></div>
            </div>
            <div class="design__body"><a class="design__scroll" href="#">
                    <div class="design__icon"><img class="design__pic" src="img/scroll.svg" alt="Scroll "></div>
                    <div class="design__text">Using Collab</div>
                </a>
                <div class="design__container">
                    <div class="design__slider js-design-slider">
                        <div class="design__slide" data-aos="animation-translate-y" data-aos-delay="0">
                            <div class="design__view">
                                <div class="design__preview"><img class="design__pic" src="img/design-pic-1.png" alt=""></div>
                            </div>
                            <div class="design__details">
                                <div class="design__category">Design</div>
                                <div class="design__info">Automation Color Picker</div>
                                <div class="design__text">Use the smart color picker to easily select and apply colors.</div><a class="design__link" href="login.html">Get started<svg class="icon icon-arrow-right">
                                        <use xlink:href="img/sprite.svg#icon-arrow-right"></use>
                                    </svg></a>
                            </div>
                        </div>
                        <div class="design__slide" data-aos="animation-translate-y" data-aos-delay="200">
                            <div class="design__view">
                                <div class="design__preview"><img class="design__pic" src="img/design-pic-2.png" alt=""></div>
                            </div>
                            <div class="design__details">
                                <div class="design__category">Build</div>
                                <div class="design__info">Component Builder Guide</div>
                                <div class="design__text">Using the Component Builder with your projects.</div><a class="design__link" href="login.html">Get started<svg class="icon icon-arrow-right">
                                        <use xlink:href="img/sprite.svg#icon-arrow-right"></use>
                                    </svg></a>
                            </div>
                        </div>
                        <div class="design__slide" data-aos="animation-translate-y" data-aos-delay="400">
                            <div class="design__view">
                                <div class="design__preview"><img class="design__pic" src="img/design-pic-3.png" alt=""></div>
                            </div>
                            <div class="design__details">
                                <div class="design__category">Ship</div>
                                <div class="design__info">Cross-platform Deployment</div>
                                <div class="design__text">Deploy your projects more efficiently for web &amp; mobile.</div><a class="design__link" href="login.html">Get started<svg class="icon icon-arrow-right">
                                        <use xlink:href="img/sprite.svg#icon-arrow-right"></use>
                                    </svg></a>
                            </div>
                        </div>
                        <div class="design__slide" data-aos="animation-translate-y">
                            <div class="design__view">
                                <div class="design__preview"><img class="design__pic" src="img/design-pic-1.png" alt=""></div>
                            </div>
                            <div class="design__details">
                                <div class="design__category">Design</div>
                                <div class="design__info">Automation Color Picker</div>
                                <div class="design__text">Use the smart color picker to easily select and apply colors.</div><a class="design__link" href="login.html">Get started<svg class="icon icon-arrow-right">
                                        <use xlink:href="img/sprite.svg#icon-arrow-right"></use>
                                    </svg></a>
                            </div>
                        </div>
                        <div class="design__slide" data-aos="animation-translate-y">
                            <div class="design__view">
                                <div class="design__preview"><img class="design__pic" src="img/design-pic-2.png" alt=""></div>
                            </div>
                            <div class="design__details">
                                <div class="design__category">Build</div>
                                <div class="design__info">Component Builder Guide</div>
                                <div class="design__text">Using the Component Builder with your projects.</div><a class="design__link" href="login.html">Get started<svg class="icon icon-arrow-right">
                                        <use xlink:href="img/sprite.svg#icon-arrow-right"></use>
                                    </svg></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>-->
    <div class="details">
        <div class="details__center center">
            <div class="details__head" data-aos="animation-scale-y">
                <h2 class="details__title title">Risultati che<br>parlano da soli<span class="title__color">.</span></h2>
                <div class="details__info info">I numeri che sostengono la tua tranquillità online</div>
            </div>
            <div class="details__row">
                <div class="details__container">
                    <div class="details__slider js-details-slider">
                        <div class="details__slide">
                            <div class="details__counter"><span class="details__number">3.15</span> Miliardi</div>
                            <div class="details__text">Richieste HTTP servite con successo per anno</div>
                            <div class="details__control">
                                <a class="details__link" href="/sicurezza">Provalo ora</a>
                            </div>
                        </div>
                        <div class="details__slide">
                            <div class="details__counter"><span class="details__number">1.9</span> Miliardi</div>
                            <div class="details__text">Richieste malevole identificate e bloccate per anno</div>
                            <div class="details__control">
                                <a class="details__link" href="/sicurezza">Provalo ora</a>
                            </div>
                        </div>
                        <div class="details__slide">
                            <div class="details__counter"><span class="details__number">40</span></div>
                            <div class="details__text">Diverse metriche utili per il monitoraggio delle abitudini dei tuoi visitator</div>
                            <div class="details__control">
                                <a class="details__link" href="/sicurezza">Provalo ora</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="details__graphic">
                    <div class="details__mobile"><img class="details__pic" src="img/mobile.svg" alt="Mobile Frame ">
                        <div class="details__box">
                            <div class="details__chart" data-aos data-aos-offset="0" data-aos-delay="300" data-aos-duration="1000"><img class="details__pic" src="img/chart.svg" alt="Chart "></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="details__hand"><img class="details__pic js-parallax" data-scale="1.2" data-orientation="up" src="img/hand-5.png" alt="Hand "></div>
            <div class="details__line"><img class="details__pic" src="img/line-2.svg" alt="Line "></div>
            <div class="details__circles">
                <div class="details__circle"></div>
                <div class="details__circle js-rellax" data-rellax-speed="-.5"></div>
            </div>
        </div>
    </div>

    <div class="roadmap">
        <div class="roadmap__center center">
            <div class="roadmap__head">
                <div class="roadmap__wrap" data-aos="animation-scale-y">
                    <div class="roadmap__stage stage">Crescita e sviluppo</div>
                    <h2 class="roadmap__title title">La nostra <span class="title__color">evoluzione</span>.</h2>
                    <div class="roadmap__info">Dalla fusione di <span class="title__color">ITEssential</span> e del progetto <span class="title__color">Sintro</span>, ha origine la realtà di Wised.</div>
                </div>
                <div class="roadmap__view" data-aos>
                    <div class="roadmap__calendar"><img class="roadmap__pic" src="img/roadmap-pic.png" alt="Calendar and clock "></div>
                    <div class="roadmap__bg"><img class="roadmap__pic js-parallax" data-scale="1.5" data-orientation="left" src="img/roadmap-pic-1.png" alt="Cubes "></div>
                </div>
            </div>
            <div class="roadmap__body">
                <div class="roadmap__category">Inizio</div>
                <div class="roadmap__box">
                    <div class="roadmap__top">
                        <div class="roadmap__col">
                            <div class="roadmap__desc">
                                <div class="roadmap__category">Anno</div>
                                <div class="roadmap__year">2020</div>
                            </div>
                        </div>
                        <div class="roadmap__col">
                            <div class="roadmap__desc">
                                <div class="roadmap__category">Anno</div>
                                <div class="roadmap__year">2022</div>
                            </div>
                        </div>
                        <div class="roadmap__col">
                            <div class="roadmap__desc">
                                <div class="roadmap__category">Anno</div>
                                <div class="roadmap__year">2024</div>
                            </div>
                        </div>
                        <div class="roadmap__col">
                            <div class="roadmap__desc">
                                <div class="roadmap__category">Anno</div>
                                <div class="roadmap__year">2026</div>
                            </div>
                        </div>
                    </div>
                    <div class="roadmap__lines">
                        <div class="roadmap__line" style="width: 100%;">
                            <div class="roadmap__progress" style="width: 50%;" data-aos>ITEssential | Web Design</div>
                        </div>
                        <div class="roadmap__line">
                            <div class="roadmap__progress" style="width: 80%;" data-aos data-aos-delay="200">Wised S.r.l.</div>
                        </div>
                        <div class="roadmap__line">
                            <div class="roadmap__progress" style="width: 50%;" data-aos data-aos-delay="400">Sintro | IT Security</div>
                        </div>
                    </div>
                </div>
                <div class="roadmap__category">Futuro</div>
            </div>
        </div>
        <div class="roadmap__circles">
            <div class="roadmap__circle"></div>
            <div class="roadmap__circle"></div>
        </div>
        <div class="roadmap__preview" data-aos data-aos-duration="1000" data-aos-delay="300"><img class="roadmap__pic" src="img/roadmap-pic-2.svg" alt="Dots overlay "></div>
    </div>
    <!--<div class="accord js-accord">
        <div class="accord__center center">
            <div class="accord__row">
                <div class="accord__details">
                    <div class="accord__top" data-aos="animation-scale-y">
                        <div class="accord__title title title_sm">Technological maturity.</div>
                        <div class="accord__text">Collab is fast. Files are always up to date and ready for production. </div>
                    </div>
                    <ul class="accord__list" data-aos="animation-scale-y" data-aos-delay="200">
                        <li>Collab Cloud</li>
                        <li>Collab Design</li>
                        <li>Collab Share</li>
                        <li>Collab Code</li>
                    </ul>
                </div>
                <div class="accord__wrap">
                    <div class="accord__item js-accord-item active" data-aos="animation-scale-y">
                        <div class="accord__head js-accord-head">
                            <div class="accord__icon"><img class="accord__pic" src="img/layout-3.svg" alt="Responsive "></div>
                            <div class="accord__category">In research</div>
                        </div>
                        <div class="accord__body js-accord-body" style="display: block; background-image: url('img/accord-pic.png');">
                            <ul class="accord__desc">
                                <li>3+ user</li>
                                <li>5 TB of secure storage</li>
                                <li>Premium productivity features and simple, secure file sharing</li>
                            </ul>
                        </div>
                    </div>
                    <div class="accord__item js-accord-item" data-aos="animation-scale-y">
                        <div class="accord__head js-accord-head">
                            <div class="accord__icon"><img class="accord__pic" src="img/layout-3.svg" alt="Responsive "></div>
                            <div class="accord__category">Drafted</div>
                        </div>
                        <div class="accord__body js-accord-body" style="background-image: url('img/accord-pic.png');">
                            <ul class="accord__desc">
                                <li>3+ user</li>
                                <li>5 TB of secure storage</li>
                                <li>Premium productivity features and simple, secure file sharing</li>
                            </ul>
                        </div>
                    </div>
                    <div class="accord__item js-accord-item" data-aos="animation-scale-y">
                        <div class="accord__head js-accord-head">
                            <div class="accord__icon"><img class="accord__pic" src="img/layout-3.svg" alt="Responsive "></div>
                            <div class="accord__category">In development</div>
                        </div>
                        <div class="accord__body js-accord-body" style="background-image: url('img/accord-pic.png');">
                            <ul class="accord__desc">
                                <li>3+ user</li>
                                <li>5 TB of secure storage</li>
                                <li>Premium productivity features and simple, secure file sharing</li>
                            </ul>
                        </div>
                    </div>
                    <div class="accord__item js-accord-item" data-aos="animation-scale-y">
                        <div class="accord__head js-accord-head">
                            <div class="accord__icon"><img class="accord__pic" src="img/layout-3.svg" alt="Responsive "></div>
                            <div class="accord__category">Proposed</div>
                        </div>
                        <div class="accord__body js-accord-body" style="background-image: url('img/accord-pic.png');">
                            <ul class="accord__desc">
                                <li>3+ user</li>
                                <li>5 TB of secure storage</li>
                                <li>Premium productivity features and simple, secure file sharing</li>
                            </ul>
                        </div>
                    </div>
                    <div class="accord__item js-accord-item" data-aos="animation-scale-y">
                        <div class="accord__head js-accord-head">
                            <div class="accord__icon"><img class="accord__pic" src="img/layout-3.svg" alt="Responsive "></div>
                            <div class="accord__category">Completed</div>
                        </div>
                        <div class="accord__body js-accord-body" style="background-image: url('img/accord-pic.png');">
                            <ul class="accord__desc">
                                <li>3+ user</li>
                                <li>5 TB of secure storage</li>
                                <li>Premium productivity features and simple, secure file sharing</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>-->

    <div class="access">
        <div class="access__center center">
            <div class="access__view" data-aos data-aos-delay="300">
                <div class="access__preview"><img class="access__pic" src="img/access-pic-2.png" alt="Customization "></div>
                <div class="access__preview"><img class="access__pic js-parallax" data-scale="1.5" data-orientation="up" src="img/access-pic-1.png" alt="Video Playback "></div>
                <div class="access__preview"><img class="access__pic js-parallax" data-scale="1.5" data-orientation="down" src="img/main-pic-2.png" alt="User Card "></div>
            </div>
            <div class="access__wrap" data-aos="animation-scale-y">
                <div class="access__info">Rimani aggiornato sulle ultime novità! <br>Iscriviti con la tua email.</div>
                <form class="access__form" action="https://wised.us17.list-manage.com/subscribe/post" method="POST">
                    <input type="hidden" name="u" value="9c062960ce3ec7beba366e8f0">
                    <input type="hidden" name="id" value="ea3556c723">

                    <div class="access__field"><input class="access__input" type="email" placeholder="Email" name="MERGE0">
                        <div class="access__icon"><img class="access__pic" src="img/sending-mail.svg" alt="Sending email "></div>
                    </div><button class="access__btn btn btn_purple" type="submit">Conferma</button>
                </form>
            </div>
        </div>
        <div class="access__circles">
            <div class="access__circle" data-aos data-aos-offset="300"></div>
            <div class="access__circle" data-aos data-aos-delay="100" data-aos-offset="300"></div>
            <div class="access__circle" data-aos data-aos-delay="200" data-aos-offset="300"></div>
        </div>
        <div class="access__clouds">
            <div class="access__cloud"><img class="access__pic js-parallax" data-scale="1.5" data-orientation="left" src="img/cloud.png" alt="Cloud "></div>
            <div class="access__cloud"><img class="access__pic js-parallax" data-scale="1.5" data-orientation="right" src="img/cloud.png" alt="Cloud "></div>
        </div>
        <div class="access__balls">
            <div class="access__ball"></div>
            <div class="access__ball"></div>
            <div class="access__ball"></div>
            <div class="access__ball"></div>
            <div class="access__ball js-rellax" data-rellax-speed="-1"></div>
            <div class="access__ball"></div>
            <div class="access__ball js-rellax" data-rellax-speed="1"></div>
        </div>
    </div>

    <div class="tool">
        <div class="tool__center center">
            <div class="tool__container">
                <div class="tool__wrap">
                    <h2 class="tool__title title" data-aos="animation-scale-y" style="width: 100%;">Sei già dei nostri? <br /> Consulta i tuoi documenti.</h2>
                    <div class="tool__info info" data-aos="animation-scale-y" data-aos-delay="150">Consulta i tuoi documenti. </div>
                    <div class="tool__btn" data-aos="animation-scale-y" data-aos-delay="300"><a class="btn btn_white js-popup-open" href="https://customer.wised.it/" data-effect="mfp-zoom-in">Area riservata</a></div>
                </div>
                <div class="tool__cloud"><img class="tool__pic js-parallax" data-scale="1.5" data-orientation="right" src="img/cloud.png" alt="Cloud "></div>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
export default {
    mounted() {
    },

    methods: {
        slideToDev() {
            setTimeout(() => {
                if (document.querySelector('#sviluppo .slick-current').id == 'slick-slide20') {
                    document.querySelector('#sviluppo .js-package-next').click()
                }
            }, 1500)
        },
        
    }
}
</script>

<style scoped>
/* partners__title title from mobile must have font-size 3rem */
@media (max-width: 767px) {
    .partners__title {
        font-size: 3rem!important;
    }

    /* details__slide must have all same height */
    .details__slider {
        height: 350px!important;
    }

    .details__slide {
        height: 350px!important;
    }

    .details__number {
        font-size: 6rem!important;
    }

    .speed__number span {
        font-size: 6rem!important;
        padding-left: 0.5rem;
    }

    
}

</style>

<style>
@media (max-width: 767px) {
    /*body, when has child mfp-bg must not scroll*/
    body:has(.mfp-bg) {
        overflow: hidden!important;
    }

    .mfp-content {
        overflow: auto!important;
        height: 100%!important;
    }
}
</style>